<template>
	<div class="bg-light">
		<div class="position-relative">
			<img :src="imgPortada" class="img-background w-100" style="height: 95px;">
		</div>
		<div class="position-relative">
			<div class="container pt-3">
				<div class="row mb-3">
					<div class="col-lg-3 mb-3">
						<div v-if="mobile">
							<div class="dropdown">
								<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuAdminIndex1" data-bs-toggle="dropdown" aria-expanded="false">
									Menú
								</button>
								<ul class="dropdown-menu" aria-labelledby="dropdownMenuAdminIndex1">
									<li>	
										<router-link :to="{name: 'admin.index'}" class="dropdown-item" active-class="active">
											Productos
										</router-link>
									</li>
									<li class="ms-3">
										<router-link :to="{name: 'admin.productos.marcas.index'}" class="dropdown-item" active-class="active">
											> Marcas
										</router-link>
									</li>
									<li class="ms-3">
										<router-link :to="{name: 'admin.productos.grupos.index'}" class="dropdown-item" active-class="active">
											> Grupos de productos
										</router-link>
									</li>
									<li class="ms-3">
										<router-link :to="{name: 'admin.productos.tipos.index'}" class="dropdown-item" active-class="active">
											> Tipos de grupos
										</router-link>
									</li>
									<li>
										<router-link :to="{name: 'admin.proveedores.index'}" class="dropdown-item" active-class="active">
											Proveedores
										</router-link>
									</li>
									<li>
										<router-link :to="{name: 'admin.proveedores.tipos.index'}" class="dropdown-item ms-3" active-class="active">
											> Tipos de proveedores
										</router-link>
									</li>
									
									<li>
										<router-link :to="{name: 'admin.paises.index'}" class="dropdown-item" active-class="active">
											Paises
										</router-link>
									</li>

									<li>
										<router-link :to="{name: 'admin.celebraciones.index'}" class="dropdown-item" active-class="active">
											Celebraciones
										</router-link>
									</li>

								</ul>
							</div>
						</div>
						<div class="card" v-else>
							<div class="card-body">
								<div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
									<router-link :to="{name: 'admin.index'}" class="nav-link" active-class="active">
										Productos
									</router-link>
									<router-link :to="{name: 'admin.productos.marcas.index'}" class="nav-link ms-3" active-class="active">
										> Marcas
									</router-link>
									<router-link :to="{name: 'admin.productos.grupos.index'}" class="nav-link ms-3" active-class="active">
										> Grupos de productos
									</router-link>
									<router-link :to="{name: 'admin.productos.tipos.index'}" class="nav-link ms-3" active-class="active">
										> Tipos de grupos
									</router-link>

									<router-link :to="{name: 'admin.proveedores.index'}" class="nav-link" active-class="active">
										Proveedores
									</router-link>
									<router-link :to="{name: 'admin.proveedores.tipos.index'}" class="nav-link ms-3" active-class="active">
										> Tipos de proveedores
									</router-link>

									<router-link :to="{name: 'admin.paises.index'}" class="nav-link" active-class="active">
										Paises
									</router-link>
									<router-link :to="{name: 'admin.celebraciones.index'}" class="nav-link" active-class="active">
										Celebraciones
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9 mb-3">
						<router-view />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgPortada from '@/assets/img/backgrounds/contacto.jpg'
	export default {
		data(){
			return {
				imgPortada
			}
		},
		computed:{
			mobile(){
				return this.$store.getters.mobile
			}
		}
	}
</script>